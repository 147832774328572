import Vue from "vue";
import Router from "vue-router";
import store from "../store/index";
import { getLoginInfo } from "../utils";
import { Toast } from 'vant';

Vue.use(Router);

const router = new Router({
    routes: [
        {
            path: "/",
            redirect: "/index"
        },
        {
            path: "/weixin/verify",
            name: "reservation",
            component: () => import("@/views/weixin/verify"),
            meta: {
                title: "实名认证",
                auth: 'login',
                keepAlive: false
            }
        },
        {
            path: "/reservation/index",
            name: "reservationIndex",
            component: () => import("@/views/reservation/index"),
            meta: {
                title: "预约挂号",
                requireMobile: true,
                auth: 'role',
                keepAlive: true,
                scollTopPosition: 0,
                tabIndex: 0,
            }
        },
        {
            path: "/reservation/history/detail",
            name: "reservationDetail",
            component: () => import("@/views/reservation/detail"),
            meta: {
                title: "预约记录",
                requireMobile: true,
                auth: 'role',
                keepAlive: false,
            }
        },
        {
            path: "/reservation/history",
            name: "reservationHistory",
            component: () => import("@/views/reservation/history"),
            meta: {
                requireMobile: true,
                title: "预约记录",
                auth: 'role',
                keepAlive: true,
                scollTopPosition: 0,
                tabIndex: 0,
                itemId: 0,
            }
        },
        {
            path: "/reservation/doctor",
            name: "doctor",
            component: () => import("@/views/reservation/doctor"),
            meta: {
                title: "医生详情",
                auth: 'login'
            }
        },
        {
            path: "/article/list",
            component: () => import("@/views/article/list"),
            meta: {
                title: "企业内刊",
                auth: 'role',
                keepAlive: true,
            }
        },
        {
            path: "/article/list/detail/:id",
            component: () => import("@/views/article/detail"),
            meta: {
                title: "内刊详情",
                auth: 'role',
            }
        },
        {
            path: "/article/list/pdfview",
            name: "pdfview",
            component: () => import("@/views/article/pdfview"),
            meta: {
                title: "在线查看",
                auth: 'role',
            }
        },
        {
            path: "/business/list",
            name: "buslist",
            component: () => import("@/views/business/list"),
            meta: {
                title: "业务查询",
                auth: 'role',
            }
        },
        {
            path: "/cook/query",
            name: "cookquery",
            component: () => import("@/views/cook/query"),
            meta: {
                title: "中药煎配查询",
                requireMobile: true,
                auth: 'role',
            }
        },
        {
            path: "/cook/list",
            name: "cooklist",
            component: () => import("@/views/cook/list"),
            meta: {
                title: "煎配查询",
                requireMobile: true,
                auth: 'role',
            }
        },
        {
            path: "/cook/list/detail",
            name: "cookdetail",
            component: () => import("@/views/cook/detail"),
            meta: {
                title: "中药煎药查询",
                auth: 'role',
            }
        },
        {
            path: "/login",
            component: () => import("@/views/login/index"),
            meta: {
                title: "登陆"
            }
        },
        {
            path: "/register",
            component: () => import("@/views/register/index"),
            meta: {
                title: "注册"
            }
        },
        {
            path: "/index",
            component: () => import("@/views/index/index"),
            meta: {
                title: "上海虹桥中药饮片",
            }
        },
        {
            path: "/reserve",
            component: () => import("@/views/reservation/reserve_bill"),
            meta: {
                title: "预约信息",
                auth: 'login',
            }
        },
        {
            path: "/my",
            component: () => import("@/views/my/index"),
            children: [
                {
                    path: "logout",
                    meta: {
                        title: "退出登录",
                        auth: 'login',
                    },
                    component: () => import("@/views/my/children/logout")
                },
                {
                    path: "center",
                    meta: {
                        title: "个人中心",
                        auth: 'login',
                    },
                    component: () => import("@/views/my/children/center")
                },
                {
                    path: "set",
                    meta: {
                        title: "修改密码",
                        auth: 'login',
                    },
                    component: () => import("@/views/my/children/set")
                },
                {
                    path: "edit",
                    meta: {
                        title: "绑定个人信息",
                        auth: 'login',
                    },
                    component: () => import("@/views/my/children/edit")
                },
                {
                    path: "userinfo",
                    meta: {
                        title: "个人资料",
                        auth: 'login',
                    },
                    component: () => import("@/views/my/children/userinfo")
                }
            ]
        },
        {
            path: "*",
            component: () => import("@/components/NotFound")
        }
    ]
});

router.beforeEach((to, from, next) => {
    let { title, auth } = to.meta;
    document.title = title;
    console.log('router beforeEach >> ', to);
    if (auth === 'login' || auth === 'role') {

        getLoginInfo(userInfo => {

            if (!userInfo) {
                next({
                    path: "/index"
                });
                
            } else {

                var menuList = userInfo.menuList || [];
                var hasPermission = false;
                //检查是否有该权限
                for (var i = 0; i < menuList.length; i += 1) {
                    if (to.fullPath.indexOf(menuList[i].webUrl) === 1) {
                        hasPermission = true;
                        break;
                    }
                }

                if (!hasPermission && auth === 'role') {

                    next({
                        path: "/index"
                    });

                    Toast.fail({
                        message: '您没有该模块访问权限'
                    });

                } else if (to.meta.requireMobile) {

                    //已经登录的状态， 煎配记录、预约挂号、预约记录判断是否有绑定手机号，如果没有，提示先绑定手机号，页面自动跳转到绑定手机号界面
                    if (!userInfo.mobile) {
                        //手机号未绑定，需要先到个人中心去绑定                     
                        
                        next({
                            path: "/my/center"
                        });

                        Toast.fail({
                            message: '请先进行注册'
                        });
                                                
                    } else {
                        
                        next();
                    }

                } else {

                    next();
                }

            }
        })
    } else {

        next();
    }


});

export default router;
