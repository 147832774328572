import Vue from "vue";
import "@/components/Vant";
// import "vant/lib/index.css";
import App from "./App.vue";
import router, { hasPrev } from "./router/index";
// import "@/assets/style/font/iconfont.css";
import "@/assets/style/index.less";

import $axios from "@/utils/request";
import mixin from "./mixin/index";
import store from "./store/index";


import {
    Form, Toast, Grid, GridItem, DatetimePicker,
    Picker, Dialog, NumberKeyboard, Sticky, Skeleton,
    Panel, Divider, List, PullRefresh, SwipeCell, Tag,
    Row, Col, Step, Steps, Sidebar, SidebarItem, Overlay, Empty
} from 'vant';

Vue.use(Form).use(Toast).use(Grid).use(GridItem).use(DatetimePicker)
    .use(Picker).use(Dialog).use(NumberKeyboard).use(Sticky).use(Skeleton)
    .use(Panel).use(Divider).use(List).use(PullRefresh).use(SwipeCell).use(Tag).use(Row)
    .use(Col).use(Step).use(Steps).use(Sidebar).use(SidebarItem).use(Overlay)
    .use(Empty);

// rem
import "amfe-flexible";

// 把变量挂载到vue的原型上
axios.defaults.baseURL = "http://localhost:10010";

Vue.prototype.$axios = $axios;
Vue.prototype.$user = null;
Vue.prototype.$back = function (path = '/index') {
    var current = location.href;
    history.back();
    setTimeout(() => {
        if (current === location.href) {
            this.$router.push({ path: path });
        }
    }, 100);
}

Vue.mixin(mixin);

Vue.config.productionTip = false;

new Vue({
    store,
    router,
    render: h => h(App)
}).$mount("#app");
