import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate'
Vue.use(Vuex);

const config = {
    plugins: [createPersistedState()],
    state: {
        isLogin: false,
        userInfo: {},
    },
    getters: {
        isLogin: state => state.isLogin,
        userInfo: state => state.userInfo
    },
    mutations: {
        updateLogin(state, payload) {
            state.isLogin = payload;
        },
        updateUserInfo(state, payload) {
            state.userInfo = payload;
        },
    },
    actions: {}
}
const store = new Vuex.Store(config);
export default store;