// import axios from "axios";
import store from "../store";
import router from "../router";
import qs from "qs"

// 创建axios实例
const service = axios.create({
    baseURL: "/api",//process.env.VUE_APP_URL, // api 的 VUE_APP_URL
    timeout: 50000 // 请求超时时间(因为需要调试后台,所以设置得比较大)
});

// request拦截器,在请求之前做一些处理
service.interceptors.request.use(
    config => {
        if(config.url != "/report/editReport") {
            config.data = qs.stringify(config.data);
            config.headers["Content-Type"] = "application/x-www-form-urlencoded; charset=UTF-8";
        } else {
            config.dataType = "json";
            // config.headers["dataType"] = "json";
            config.processData= false;
            config.contentType= false;
        }
        return config;
    },
    error => {
        Promise.reject(error);
    }
);

// response 拦截器,数据返回后进行一些处理
service.interceptors.response.use(
    response => {
        if(response.status == 200) {
            const res = response.data;
            return res;
        } else {
            Promise.reject("网络异常");
        }
        
        // if (res.code == "666") {
        //     return res;
        // } else if (res.code == "603") {
        //     // code为603代表token已经失效,
        //     // 提示用户,然后跳转到登陆页面
        //     router.push("/login");
        // } else {
        //     Promise.reject(res);
        // }
    },
    error => {
        Promise.reject("网络异常");
    }
);
export default service;
