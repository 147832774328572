import {
    Toast
} from 'vant';
import Vue from "vue";

import request from './request';
import { get } from 'core-js/fn/dict';
import { load, sessionSave, sessionLoad, save } from './storage';

export const env = process.env.NODE_ENV;

console.log('NODE_ENV >>> ', env);
console.log('NODE_ENV >>> ', env);
console.log('NODE_ENV >>> ', env);

export const loading = (flag) => {
    if (flag) {
        Toast.loading({
            mask: true,
            message: '加载中...'
        });
    } else {
        Toast.clear();
    }
}

export const getSearchParams = () => {
    var search = location.search;
    var result = {};
    if (search) {
        search.substring(1).split('&').forEach(p => {
            p = p.split('=');
            result[p[0]] = p[1] ? decodeURIComponent(p[1]) : '';
        })
    }
    return result;
}


const key_openid = 'openid';

const key_logininfo = 'logininfo';

const key_original_url = 'original_url';

export const getLoginInfo = (_callback) => {
    const callback = user => {
        console.log(' got login info >>> ');
        Vue.prototype.$user = user;
        _callback && _callback(user);
    }
    sessionLoad(key_logininfo, userInfo => {
        if (userInfo) {
            callback && callback(userInfo);
        } else {
            loadByOpenId(callback);
        }
    });

}

export const reLoadLoginInfo = ( callback ) => {
    sessionSave(key_logininfo, null);
    getLoginInfo(callback);
}

function loadByOpenId(callback) {

    // 强制设置 缓存的 openid 
    // save(key_openid, 'oGbSjt6NyWwfxJ7SS8LKBXgZ0Z3U');

    // sessionLoad(key_openid, openId => {
    load(key_openid, openId => {
        if (!openId) {
            if ("development" === env) {
                // openId = 'oBCQbt2yf7d-OxFuAF4tTJYbiI1I';// 屈跃庭
                // // openId = 'osp3lt91BuJ_JkwoqawsCI5b8IZM';//测试账号
                openId = "oGbSjt6NyWwfxJ7SS8LKBXgZ0Z3U"; // hq.tangusoft.com 李立超
                // openId = "opoobuO24GlBrrv26ErOaL7msc5k"; // 测试公众号 李立超
            }
        }

        if (!openId) {

            var search = getSearchParams();
            
            if (search.code) {

                request.get("/wechat/bindWeiXin", { params: { code: search.code } })
                    .then((res) => {
                        if (res && res.data) {
                            res.data.processConfig = res.extra;
                            sessionSave(key_logininfo, res.data);
                            save(key_openid, res.data.openId);
                            sessionLoad(key_original_url, url => {
                                sessionSave(key_original_url, null);
                                if (url && url !== location.href) {
                                    location.href = url;
                                } else {
                                    callback && callback(res.data);
                                }
                            })
                        } else {
                            sessionSave(key_logininfo, null);
                            Toast.fail({
                                message: (res && res.errmsg) || '服务器异常，请重试'
                            });
                        }

                    })
                    .catch((err) => {
                        Toast.fail("err=" + err);
                    });

            } else {

                tryAuth2();
                return;

            }
        } else {
            
            request.get("/wechat/getUserInfo", { params: { openId } })
                .then((res) => {
                    if (res && res.data) {
                        res.data.processConfig = res.extra;
                        sessionSave(key_logininfo, res.data);
                        sessionLoad(key_original_url, url => {
                            sessionSave(key_original_url, null);
                            if (url && url !== location.href) {
                                location.href = url;
                            } else {
                                callback && callback(res.data);
                            }
                        })
                    } else {
                        sessionSave(key_logininfo, null);
                        Toast.fail((res && res.errmsg) || '服务器异常，请重试');
                    }
                })
                .catch((err) => {
                    console.log("err", err);
                });
        }

    });

}


function tryAuth2() {

    request.get("/config/wx")
        .then((res) => {
            var appId = res.data.wxAppId;
            var url = location.origin + location.pathname + location.search;
            var weixinUrl = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${encodeURI(url)}&response_type=code&scope=snsapi_userinfo&state=1#wechat_redirect`;
            sessionSave(key_original_url, location.href);
            setTimeout(() => {
                window.location.href = weixinUrl;
            }, 200);

        })
        .catch((err) => {
            Toast.fail('服务器连接异常，请重试');
        });

}
